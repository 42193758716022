import React, { useState } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Stack from '@mui/material/Stack'
import ReactPlayer from 'react-player'

import config from '../config'
import { SessionProps } from '../models'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'

interface PlayerProps {
  sessionProps?: SessionProps
}

const Player = (props: PlayerProps): JSX.Element => {
  const { sessionProps } = props

  const [url, setUrl] = useState('')

  const handlePlay = async (): Promise<void> => {
    // const fileId = '1b6928d6-3072-4550-b269-94507e7599c9'
    const fileId = '4b14dfe0-3509-4eca-92d8-5d54a49444e3'
    if (sessionProps == null) return undefined
    if (!sessionProps.groups.includes('sakeet')) return undefined
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: sessionProps.idToken,
        Accept: 'binary/octet-stream'
      }
    }
    const response = await fetch(`${config.ApiBaseUrl}/download/${fileId}`, requestOptions)
    const status = response.status
    console.log('File download response', status)
    if (status !== 200) return undefined
    const blob = await response.blob()
    console.log('Got binary blob of size/type ', blob.size, blob.type)
    setUrl(URL.createObjectURL(blob))
    // TODO: call URL.revokeObjectURL() to free memory when url no longer needed
  }

  return (
    <div className="player">
      <Stack spacing={1}>
        <Tooltip title="Play">
          {/* eslint-disable-next-line */}
          <Button variant="outlined" startIcon={<PlayArrowIcon />} onClick={handlePlay}>
            Play
          </Button>
        </Tooltip>
        <ReactPlayer
          width="100%"
          height="100%"
          playing={false}
          url={url}
          onError={(e, data) => console.log(`error: ${String(e)} - ${String(data)}`)}
          onReady={(player) => console.log('ready')}
          onStart={() => console.log('start')}
          onPlay={() => console.log('play')}
          controls={true}
        />
      </Stack>
    </div>
  )
}

export default Player
